import Portal from './Portal';
import React from 'react';
import Styled from 'styled-components';
import useDropdown from '../util/useDropdown';

type Placement = 'top' | 'bottom' | 'left' | 'right';
type Align = 'left' | 'center' | 'right';

type Props = {
    children: any;
    content: React.ReactNode;
    topOffset?: number;
    leftOffset?: number;
    width?: string;
    placement?: Placement;
    /** Has no effect if the placement is `left` or `right` */
    align?: Align;
    cursor?: React.CSSProperties['cursor'];
    tooltipDisplay?: React.CSSProperties['display'];
    tooltipControlStyle?: React.CSSProperties;
    floaterStyle?: Record<string, any>;
    /** Allows the tooltip content to wrap onto multiple lines and have an auto-width.
     * You can override the maxWidth, which is `14rem` by default */
    autoWidthFloater?: boolean | {maxWidth: string};
};

const Tooltip = Styled.div<{
    cursor?: React.CSSProperties['cursor'];
    display: React.CSSProperties['display'];
}>`
    position: relative;
    min-width: 0;
    cursor: ${(p) => p.cursor};
    display : ${(p) => p.display};
`;

const Floater = Styled.div<{
    placement: Placement;
    align: Align;
    width?: string;
    autoWidthFloater?: boolean | {maxWidth: string};
}>`${(p) => {
    const {background, foreground: copy} = p.theme.colors;
    const {align, placement, width, autoWidthFloater} = p;

    return `
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        pointer-events: none;
        border-radius: 3px;
        background-color: ${copy};
        color: ${background};
        padding: .5rem .75rem;
        line-height: 1.4;
        font-size: .9em;
        max-width: 24rem;
        position: absolute;
        z-index: ${p.theme.zIndices.Tooltip || 0};

        ${width ? `width: ${width}; min-width: ${width};` : ''}

        ${
            autoWidthFloater
                ? `
            width: 'max-content';
            max-width: ${
                typeof autoWidthFloater === 'boolean' ? '14rem' : autoWidthFloater.maxWidth
            };
            min-width: '5rem';
            white-space: unset;
        `
                : ''
        }

        &::before {
            display: block;
            content: ' ';
            border: .5rem solid transparent;
            width: 1rem;
            height: 1rem;
            position: absolute;
        }

        &::before {
            // alignment
            ${align === 'left' ? `left: .5rem;` : ''}
            ${align === 'center' ? `left: calc(50% - .5rem);` : ''}
            ${align === 'right' ? `left: calc(100% - 1.5rem);` : ''}

            // placement
            ${placement === 'bottom' ? `top: -1rem; border-bottom-color: ${copy};` : ''}
            ${placement === 'top' ? `top: 100%; border-top-color: ${copy};` : ''}
            ${placement === 'right' ? `right: 100%; left: auto; border-right-color: ${copy};` : ''}
            ${placement === 'left' ? `left: 100%; border-left-color: ${copy};` : ''}

            // left and right placement have the same top property
            ${placement === 'right' || placement === 'left' ? 'top: calc(50% - 0.5rem);' : ''}
        }
`;
}}`;

export default function TooltipComponent(props: Props) {
    const {children} = props;
    const {content} = props;
    const {topOffset, leftOffset} = props;
    const {placement = 'bottom'} = props;
    const {align = 'center'} = props;
    const {tooltipDisplay = 'block'} = props;
    const {width} = props;
    const {floaterStyle} = props;

    // Add default offset to the corresponding axis if no offset was supplied
    let top = topOffset;
    let left = leftOffset;
    if (typeof top === 'undefined' && (placement === 'bottom' || placement === 'top')) top = 16;
    if (typeof left === 'undefined' && (placement === 'right' || placement === 'left')) left = 16;

    const {open, setOpen, node, menuNode, menuStyle} = useDropdown({
        offset: {top, left},
        align,
        placement
    });

    const cursor = props.cursor ?? (content ? 'pointer' : 'default');

    return (
        <Tooltip
            // using Pointer events here because in chromium browsers
            // onMouseLeave doesn't fire on disabled buttons
            // @link https://github.com/facebook/react/issues/18753#issuecomment-630771296
            onPointerEnter={() => setOpen(true)}
            onPointerLeave={() => setOpen(false)}
            onClick={() => setOpen(false)}
            cursor={cursor}
            display={tooltipDisplay}
            ref={node}
            style={props.tooltipControlStyle}
        >
            {children}
            {open && content && (
                <Portal>
                    <Floater
                        style={{...menuStyle, ...floaterStyle}}
                        ref={menuNode}
                        align={align}
                        placement={placement}
                        width={width}
                        autoWidthFloater={props.autoWidthFloater}
                    >
                        {content}
                    </Floater>
                </Portal>
            )}
        </Tooltip>
    );
}
