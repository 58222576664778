import React from 'react';
import {safeLazyImport, DeadEnd, Boom} from 'bigdatr-style';
import {createRouterContext, BoringRoute, LazyRoute, Parse} from 'trouty';

const Dashboard = safeLazyImport(() => import('./Dashboard'));
const WorkflowView = safeLazyImport(() => import('../workflow/WorkflowView'));
const NotebookWorkflowForm = safeLazyImport(
    () => import('../workflow/notebookForm/NotebookWorkflowForm')
);

export type NotebookTab = 'info' | 'notebooks' | 'sensors' | 'form';
export type RunOutputTab = 'logs' | 'html' | 'iterations';
export type RunTaskTab = 'runs' | 'tasks';
export type WorkflowRouteArgs = {
    workflowId: string;
    runId?: number;
    stageId?: string;
    runOutputTab?: RunOutputTab;
    runTaskTab?: RunTaskTab;
    iterationIndex?: number;
};

const {
    routes,
    RoutesProvider: DataingestRoutesProvider,
    useRoutes
} = createRouterContext({
    dashboard: BoringRoute({
        path: '/',
        component: Dashboard
    }),
    workflow: LazyRoute<WorkflowRouteArgs>({
        path: '/workflow/:workflowId',
        parse: {
            workflowId: Parse.param.string((x) => x || ''),
            runId: Parse.query.number((x) => x),
            stageId: Parse.query.string((x) => x),
            runOutputTab: Parse.query.string((x) => (x || 'logs') as RunOutputTab),
            runTaskTab: Parse.query.string((x) => (x || 'runs') as RunTaskTab),
            iterationIndex: Parse.query.number((x) => x)
        },
        component: WorkflowView
    }),
    notebookWorkflowForm: LazyRoute<{id?: string; tab?: NotebookTab}>({
        path: '/notebook',
        parse: {
            id: Parse.query.string((x) => x || ''),
            tab: Parse.query.string((x) => (x || 'info') as NotebookTab)
        },
        component: NotebookWorkflowForm
    }),
    notFound: BoringRoute({
        path: '',
        component: () => <DeadEnd boom={Boom.notFound('page')} />
    })
});

export {routes, DataingestRoutesProvider, useRoutes};
