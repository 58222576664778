import Hubspot from './Hubspot';

export interface ThirdParty {
    readonly identify: Function;
    readonly page: Function;
    readonly trackEvent: Function;
    readonly trackError: Function;
    readonly chat: Function;
    readonly reloadChat?: Function;
    readonly addBreadcrumb?: (category: string, message: string) => void;
}

export type AnalyticsIdentifyProps = {
    id?: string;
    email?: string;
    release?: string;
    name?: string;
    firstname?: string;
    hasCreative?: boolean;
    hasMediaValue?: boolean;
    teamId?: string;
    teamName?: string;
    experimentId?: string;
    isStaff?: boolean;
    createdAt?: string;
    teamCount?: number;
};

export default class Analytics {
    _analytics: Map<string, ThirdParty>;

    constructor(analytics: Record<string, ThirdParty>) {
        this._analytics = new Map();

        if (this._shouldTrack()) {
            this._analytics = new Map(Object.entries(analytics));
        }
    }

    get analytics() {
        return [...this._analytics.values()];
    }

    //
    // Private
    private _shouldTrack(): boolean {
        if (typeof window !== 'undefined') {
            const hostnames = ['bigdatr.com', 'bigdatr.codes', 'localhost'];
            if (!hostnames.some((hostname) => window?.location.hostname.indexOf(hostname) > -1))
                return false;
            return true;
        } else {
            return false;
        }
    }

    private _callAll(method: string, ...args: Array<unknown>) {
        const consoleArgs = [`%c[analytics ${method}]`, 'color: #93619f;', ...args];
        if (typeof process !== 'undefined' && process.env.ENABLE_TRACKING_LOGS) {
            // eslint-disable-next-line no-console
            console.log(...consoleArgs);
        }
        this.analytics.forEach((analytics) => analytics[method]?.(...args));
    }

    /**
     * Check if Hubspot was provided as a third party
     */
    private get hubspotProvided() {
        return this.analytics.some((aa) => aa instanceof Hubspot);
    }

    /**
     * Check if Hubspot is available in the browser
     * Useful when wanting to open a hubspot chat
     * This could be false when a user is using an adblocker
     */
    private get hubspotAvailable() {
        return this.hubspotProvided && !!window.HubSpotConversations;
    }

    add(name: string, thirdparty: ThirdParty) {
        this._analytics.set(name, thirdparty);
    }

    //
    // Public

    /**
    Hubspot doesn't give us much of an api for chat, so we dont really know if it has loaded yet.
    Pretty hacky but will poll a few times if the chat doesn't load on the first attempt.
    */

    addBreadcrumb(category: string, message: string) {
        this._callAll('addBreadcrumb', category, message);
    }

    openChatOnPageLoad() {
        if (this.hubspotProvided) {
            let tries = 0;
            const pollToOpenChat = () => {
                if (this.hubspotAvailable || tries > 3) {
                    return this.chat({fallbackHref: ''});
                }
                tries++;
                setTimeout(pollToOpenChat, 2000);
            };
            pollToOpenChat();
        }
    }

    chat(options: {fallbackHref?: string; message?: string} = {}) {
        const {fallbackHref = 'mailto:support@bigdatr.com', message} = options;

        // Open a new email window as a fallback when Hubspot chat isn't available
        // Fallback needs to happen here instead of Hubspot thirdparty due to needing to fallback when Hubspot isn't provided
        if (!this.hubspotAvailable) {
            if (fallbackHref.startsWith('mailto:')) {
                // window.open and email hrefs causes a window flash (these open as a seperate window regardless)
                location.href = fallbackHref;
            } else {
                window.open(fallbackHref);
            }
        }

        this._callAll('chat', message);
    }

    reloadChat() {
        this._callAll('reloadChat');
    }

    identify(props: AnalyticsIdentifyProps) {
        this._callAll('identify', props);
    }

    page() {
        this._callAll('page', window.location.href);
    }

    trackEvent(
        eventName: string,
        props: Record<string, any> = {},
        trackingOptions: Record<string, boolean> = {}
    ) {
        // stringify & parse to make sure we track a plain object
        this._callAll('trackEvent', eventName, JSON.parse(JSON.stringify(props)), trackingOptions);
    }

    /**
     * Track interactions that we aren't confident need to be tracked long term
     * @param name interaction name in camelcase (eg. feedEditClick)
     * @param data metadata associated to the interaction
     */
    trackInteraction(name: string, data?: Record<string, any>) {
        this.trackEvent('interaction', {name, data: JSON.stringify(data)});
    }

    trackError(error: Error, options: Record<string, any> = {}) {
        this._callAll('trackError', error, options);
    }
}
