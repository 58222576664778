import {Box, StyledProps} from '../../../layout';
import {IconCross} from '../../../icon';
import Tooltip from '../Tooltip';
import Clickable from '../Clickable';
import React from 'react';
import {Layout, Menu, Control} from './DropdownLayoutAffordances';
import Portal from '../Portal';
import useDropdown from '../../util/useDropdown';

type Props = Pick<StyledProps, 'width' | 'maxWidth'> & {
    control: (props: {isOpen: boolean}) => React.ReactNode;
    menu: (props: {onClose: () => void}) => React.ReactNode;
    initialOpen?: boolean;
    disabled?: boolean;
    tooltip?: string | null;
    tooltipOffset?: number;
    tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
    direction?: 'top' | 'bottom';
    align?: 'right' | 'left';
    topOffset?: number;
    onClose?: () => void; // Method that is called on dropdown menu close
    onOpen?: () => void; // Method that is called on dropdown menu close
    rootLayoutStyle?: React.CSSProperties;
    rootControlStyle?: React.CSSProperties;
};
export default function Dropdown(props: Props) {
    const {tooltip} = props;
    const {control} = props;
    const {menu} = props;
    const {tooltipOffset} = props;
    const {tooltipPlacement} = props;
    const {width = '15rem'} = props;
    const {maxWidth} = props;
    const {align = 'left'} = props;
    const {direction = 'bottom'} = props;
    const {topOffset = 8} = props;
    const widthStyle = maxWidth ? {maxWidth} : {width};

    const {open, setOpen, node, menuNode, menuStyle} = useDropdown({
        align,
        enabled: !props.disabled,
        placement: direction,
        offset: {top: topOffset},
        onClose: () => props.onClose?.(),
        initialOpen: props.initialOpen
    });
    const onClose = () => setOpen(false);

    return (
        <Layout isOpen={open} style={props.rootLayoutStyle}>
            <Control
                style={props.rootControlStyle}
                onClick={() => {
                    props.onOpen?.();
                    setOpen(!open);
                }}
                isOpen={open}
                ref={node}
            >
                {tooltip ? (
                    <Tooltip
                        content={tooltip}
                        topOffset={tooltipOffset}
                        placement={tooltipPlacement}
                        children={control({isOpen: open})}
                    />
                ) : (
                    control({isOpen: open})
                )}
                {open && (
                    <Box display={{md: 'none'}}>
                        <Clickable>
                            <IconCross size="2rem" />
                        </Clickable>
                    </Box>
                )}
            </Control>
            {open && (
                <Portal>
                    <Menu
                        {...widthStyle}
                        style={menuStyle}
                        ref={menuNode}
                        isOpen={open}
                        align={align}
                        direction={direction}
                    >
                        {menu({onClose})}
                    </Menu>
                </Portal>
            )}
        </Layout>
    );
}
