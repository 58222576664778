import React from 'react';
import {Portal as ReactPortal} from 'react-portal';
import {Absolute} from '../../layout';

export default function Portal({children}: {children: React.ReactNode}) {
    return (
        <ReactPortal>
            <Absolute top={0} left={0} zIndex="Portal">
                {children}
            </Absolute>
        </ReactPortal>
    );
}
