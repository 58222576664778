import React, {useRef, useEffect, useState} from 'react';

type Config = {
    /** Has no effect if placement is `left` or `right` */
    align?: 'left' | 'right' | 'center';
    placement?: 'bottom' | 'top' | 'left' | 'right';
    offset?: {
        top?: number;
        left?: number;
    };
    initialOpen?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    enabled?: boolean;
};

export default function useDropdown({
    align = 'left',
    placement = 'bottom',
    onClose,
    onOpen,
    offset = {},
    initialOpen = false,
    enabled = true
}: Config): {
    open: boolean;
    setOpen: (next: boolean) => void;
    node: React.MutableRefObject<HTMLDivElement | null>;
    menuNode: React.MutableRefObject<HTMLDivElement | null>;
    menuStyle: {};
} {
    const node = useRef<HTMLDivElement | null>(null);
    const menuNode = useRef<HTMLDivElement | null>(null);
    const [open, setOpenState] = useState(initialOpen);

    useEffect(() => {
        if (initialOpen) onOpen?.();
    }, []);

    // @intent
    // calculate the styles to render a dropdown in a portal
    // and alway on screen
    const getStyles = (element: Element | null, menuElement: Element | null) => {
        // there is always flash of unpositioned element
        // while the dom figures out where it is.
        // make it invisible so it doesn't thrash
        if (!element || !menuElement) return {opacity: '0'};

        const {clientWidth, clientHeight} = document.documentElement;
        const menuRect = menuElement.getBoundingClientRect();
        const rootRect = element.getBoundingClientRect();
        const topOffset = offset?.top || 0;
        const leftOffset = offset?.left || 0;

        let top = 0;
        let left = 0;
        switch (placement) {
            case 'bottom':
            default: {
                top = rootRect.bottom + topOffset;
                break;
            }
            case 'top': {
                top = rootRect.top - menuRect.height - topOffset;
                break;
            }
            case 'right': {
                top = rootRect.top + rootRect.height / 2 - menuRect.height / 2;
                left = rootRect.right + leftOffset;
                break;
            }
            case 'left': {
                top = rootRect.top + rootRect.height / 2 - menuRect.height / 2;
                left = rootRect.left - menuRect.width - leftOffset;
                break;
            }
        }

        if (placement === 'bottom' || placement === 'top') {
            if (align === 'left') left = rootRect.left + leftOffset;
            if (align === 'right') left = rootRect.right + leftOffset - menuRect.width;

            // 2022-08-08 - only Tooltip uses align='center'
            if (align === 'center') {
                // This is the 'base' position of where the menu should appear. This will
                // position offscreen menus correctly
                left = rootRect.left + leftOffset + (rootRect.width / 2 - menuRect.width / 2);
            }
        }

        const edgePadding = 16;
        // Make sure the dropdown isn't off screen
        if (top < edgePadding) top = edgePadding;
        if (left < edgePadding) left = edgePadding;
        if (left + menuRect.width > clientWidth)
            left -= left + menuRect.width - clientWidth + edgePadding;
        if (top + menuRect.height > clientHeight)
            top -= top + menuRect.height - clientHeight + edgePadding;

        return {
            position: 'fixed',
            top: `${top}px`,
            left: `${left}px`,
            opacity: '1' // show the menu
        };
    };

    const [menuStyle, setStyles] = useState(getStyles(node.current, menuNode.current));

    useEffect(() => {
        if (menuNode.current && node.current) {
            setStyles(getStyles(node.current, menuNode.current));
        }
    }, [menuNode.current, node.current]);

    const setOpen = (value: boolean) => {
        setStyles(getStyles(node.current, menuNode.current));
        setOpenState(value);
        value ? onOpen?.() : onClose?.();
    };

    const handleClickOutside = (event: MouseEvent) => {
        const element = event.target;
        if (
            element instanceof Node &&
            ((menuNode.current && menuNode.current.contains(element)) ||
                (node.current && node.current.contains(element)))
        ) {
            // inside click
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (!enabled) return;
        setStyles(getStyles(node.current, menuNode.current));
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, enabled]);

    return {
        open,
        setOpen,
        node,
        menuNode,
        menuStyle
    };
}
