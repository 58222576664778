import React from 'react';
import Paper from './Paper';
import Text from './Text';
import {Box} from '../../layout';
import {Flex} from '../../layout';

export default function ModalConfirm(props: {
    title: string;
    subtitle: React.ReactNode;
    yes: React.ReactNode;
    no: React.ReactNode;
}) {
    const {title, subtitle, yes, no} = props;
    return (
        <Paper round p={4}>
            <Box mb={2}>
                <Text textStyle="heading2">{title}</Text>
            </Box>
            <Box mb={4}>
                <Text textStyle="heading4">{subtitle}</Text>
            </Box>
            <Flex width="100%" gap={2} justifyContent="flex-end">
                {no}
                {yes}
            </Flex>
        </Paper>
    );
}
